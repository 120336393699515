import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const liminaire = () => (
  <Layout>
    <SEO title="Dernières oeillades - Je suis moi-même" />
    <h3 className='underline-title'>Je suis moi-même</h3>
    <br />
    <br />
    <div className="table-responsive">
      <p>
        Je suis né moi-même<br />
        Je crois être moi-même<br />
        Je suis tout court moi-même<br />
        Tout ce qui m'environne<br />
        Confirme que je suis moi-même<br />
        En vérité je suis moi-même<br />
      </p>
      <p>
        Je suis moi-même parce que <br />
        J'aime une môme qui me plaît<br />
        Je manque d'argent qui ne m'appartient pas<br />
        Je peux m'en foutre de gens dits bien<br />
        Je ne peux pas être comme les autres<br />
      </p>
      <p>
        Pas même comme Cibalonza de mon coeur<br />
        Lea autres non plus ne peuvent être comme moi<br />
      </p>
      <p>
        Je suis moi-même encore parce que<br />
        Je peux désirer ce que je pense<br />
        Je ne peux toujours pas avoir l'objet de mon désirer<br />
        Vraiment et sans aucun doute je suis moi-même<br />
      </p>
      <p>
        Ce qui est extravagant fort farfelu<br />
        C'est que les autres me torturent pour que je devienne comme eux!<br />
        Ils savent pertinemment que je ne dois être comme eux!<br />
        Ils seraient peut-être un peu contents si je devenais un tout <br />
        <span className="par-starting"></span>petit peu comme eux<br />
      </p>
      <p>
        Malheureusement...<br />
        Heureusement je suis ravi de rester comme moi-même<br />
        <span className="par-starting"></span>de rester court comme moi-même<br />
        Pourquoi se font-ils du mauvais sang pour moi?<br />
        Leur sang est déjà amplement intoxiqué!<br />
        J'ai mon sang à moi-même<br />
        Qu'est-ce-que ça peut leur faire?<br />
        Puisqu'ils ne sont pas et ne peuvent pas être comme et moi-même? <br />
        Ils ne sont pas moi et je ne suis pas eux<br />
        Ils sont riches et pauvres<br />
        Je suis pauvre et riche<br />
      </p>
      <p>
        Qu'est-ce que ça peut bien leur faire?<br />
        Je peux devenir riche mais pas comme eux<br />
        Tout comme ils peuvent s'appauvrir mais pas comme moi <br />
        Je ne serais point eux et ils ne seront jamais moi <br />
        Où est alors leur problème pour moi?<br />
      </p>
      <p>
        Si réellement ils se soucient de moi<br />
        Pourquoi ne pas me garder moi-même?<br />
        Ce serait honnête n'est-ce pas?<br />
      </p>
      <p>
        Peuvent-ils vraiment me changer en eux?<br />
        Je serais leur faux ou faux eux<br />
        Exactement comme ils seraient faux moi<br />
      </p>
      <p>
        Si Titan n'est pas thaumaturge<br />
        Comment un Lilliputien peut-il le devenir?...<br />
      </p>
    </div>
    <br />
    <br />
    <Link to="/dernieres-oeillades-table-des-matieres">Table des matières</Link>
  </Layout>
)

export default liminaire
